define("@smith-carson/ui/templates/components/dynamic-filter/render-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U+alUjOx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"renderType\"]],\"date\"],null]],null,{\"statements\":[[4,\"if\",[[29,\"eq\",[1,[25,[\"numberValues\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[29,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"date\",\"form-control\",\"Date\",[24,0,[\"currentValue1\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"eq\",[2,[25,[\"numberValues\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[29,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"date\",\"form-control\",\"From Date\",[24,0,[\"currentValue1\"]]]]],false],[0,\"\\n        \"],[1,[29,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"date\",\"form-control\",\"To Date\",[24,0,[\"currentValue2\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[1,[25,[\"numberValues\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[29,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"text\",\"form-control\",\"\",[24,0,[\"currentValue1\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"eq\",[2,[25,[\"numberValues\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[29,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"text\",\"form-control\",\"\",[24,0,[\"currentValue2\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/dynamic-filter/render-values.hbs"
    }
  });

  _exports.default = _default;
});