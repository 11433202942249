define("js-common/models/subject-has-client-tag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;

  var _default = Model.extend({
    tag: belongsTo('subject-client-tag', {
      async: false
    }),
    subject: belongsTo('subject', {
      async: true
    })
  });

  _exports.default = _default;
});