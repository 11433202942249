define("js-common/components/subject-icons", ["exports", "js-common/templates/components/subject-icons"], function (_exports, _subjectIcons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _subjectIcons.default,
    tagName: '',
    hideFlagged: false,
    showPublished: true,
    reviewedIconComponent: 'subject-icons/reviewed',
    flaggedIconComponent: 'subject-icons/flagged',
    reportIconComponent: 'subject-icons/report',
    publishedIconComponent: 'subject-icons/published',
    clientNotesIconComponent: 'subject-icons/client-notes'
  });

  _exports.default = _default;
});