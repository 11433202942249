define("js-common/templates/components/subject-icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lSXLWVnn",
    "block": "{\"symbols\":[\"@subject\",\"@showPublished\",\"@hideFlagged\",\"@userReviewedResources\",\"@toggleReviewedTask\",\"@toggleReviewedAs\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"subject-icons\"],[9],[0,\"\\n\"],[4,\"if\",[[27,7]],null,{\"statements\":[[0,\"        \"],[15,7,[[29,\"hash\",null,[[\"reviewed\",\"flagged\",\"report\",\"published\"],[[29,\"component\",[[24,0,[\"reviewedIconComponent\"]]],[[\"subject\",\"userReviewedResources\",\"toggleReviewedTask\",\"toggleReviewedAs\"],[[24,1,[]],[24,4,[]],[24,5,[]],[24,6,[]]]]],[29,\"component\",[[24,0,[\"reportIconComponent\"]]],[[\"subject\"],[[24,1,[]]]]],[29,\"component\",[[24,0,[\"reportIconComponent\"]]],[[\"subject\"],[[24,1,[]]]]],[29,\"component\",[[24,0,[\"publishedIconComponent\"]]],[[\"subject\"],[[24,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,1,[\"presentKeyAttributes\",\"length\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\"],[11,\"class\",\"icon-key-attribute icon-key-attribute-present\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,2,[]]],null,{\"statements\":[[0,\"            \"],[6,[24,0,[\"publishedIconComponent\"]],[],[[\"@subject\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[6,[24,0,[\"reportIconComponent\"]],[],[[\"@subject\"],[[24,1,[]]]]],[0,\"\\n\"],[4,\"unless\",[[24,3,[]]],null,{\"statements\":[[0,\"            \"],[6,[24,0,[\"flaggedIconComponent\"]],[],[[\"@subject\"],[[24,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[6,[24,0,[\"clientNotesIconComponent\"]],[],[[\"@subject\"],[[24,1,[]]]]],[0,\"\\n        \"],[6,[24,0,[\"reviewedIconComponent\"]],[],[[\"@subject\",\"@userReviewedResources\",\"@toggleReviewedTask\",\"@toggleReviewedAs\"],[[24,1,[]],[24,4,[]],[24,5,[]],[24,6,[]]]]],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js-common/templates/components/subject-icons.hbs"
    }
  });

  _exports.default = _default;
});