define("@smith-carson/ui/utils/all-operators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = allOperators;

  function allOperators() {
    return {
      Contains: Ember.Object.create({
        display: 'Contains',
        value: 'contains'
      }),
      NotContains: Ember.Object.create({
        display: 'Not Contains',
        value: 'not-contains'
      }),
      Equals: Ember.Object.create({
        display: 'Is Equal',
        value: 'eq'
      }),
      NotEquals: Ember.Object.create({
        display: 'Is Not Equal',
        value: 'not-eq'
      }),
      GreaterOrEqual: Ember.Object.create({
        display: '>=',
        value: 'gte'
      }),
      LessOrEqual: Ember.Object.create({
        display: '<=',
        value: 'lte'
      }),
      Between: Ember.Object.create({
        display: 'Between',
        value: 'between'
      })
    };
  }
});