define("@smith-carson/ui/components/dynamic-filter/select-operator", ["exports", "@smith-carson/ui/templates/components/dynamic-filter/select-operator", "@smith-carson/ui/utils/all-operators"], function (_exports, _selectOperator, _allOperators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _selectOperator.default,
    operatorList: Ember.computed('renderType', function () {
      var operators = [];
      var Operators = (0, _allOperators.default)();

      switch (this.renderType) {
        case 'text':
          operators.pushObject(Operators.Contains);
          operators.pushObject(Operators.NotContains);
          operators.pushObject(Operators.Equals);
          operators.pushObject(Operators.NotEquals);
          break;

        case 'number':
        case 'money':
        case 'date':
          operators.pushObject(Operators.Equals);
          operators.pushObject(Operators.GreaterOrEqual);
          operators.pushObject(Operators.LessOrEqual);
          operators.pushObject(Operators.Between);
          break;

        case 'workflow':
          break;

        default:
          break;
      }

      return operators;
    })
  });

  _exports.default = _default;
});