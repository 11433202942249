define("@smith-carson/ui/templates/components/sc-table/quick-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rTEvv8wN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"input-group d-print-none\"],[9],[0,\"\\n  \"],[1,[29,\"sc-input\",null,[[\"type\",\"value\",\"placeholder\",\"onChange\"],[\"search\",[25,[\"searchString\"]],\"Narrow Results\",[29,\"action\",[[24,0,[]],\"triggerQuickSearch\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"input-group-append\"],[9],[0,\"\\n\"],[4,\"sc-button\",null,[[\"action\",\"color\"],[[29,\"optional\",[[25,[\"runQuickSearch\"]]],null],\"primary\"]],{\"statements\":[[0,\"        \"],[1,[29,\"sc-icon\",null,[[\"icon\",\"color\"],[\"search\",\"white\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-table/quick-search.hbs"
    }
  });

  _exports.default = _default;
});