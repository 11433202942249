define("@smith-carson/ui/components/dynamic-filter/render-values", ["exports", "@smith-carson/ui/templates/components/dynamic-filter/render-values"], function (_exports, _renderValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _renderValues.default,
    numberValues: Ember.computed('operator', function () {
      switch (this.operator) {
        case 'between':
          return 2;

        default:
          return 1;
      }
    })
  });

  _exports.default = _default;
});