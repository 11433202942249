define("@smith-carson/ui/templates/components/dynamic-filter/select-operator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d8P7pb1B",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[5,\"sc-power-select\",[],[[\"@options\",\"@selected\",\"@onchange\",\"@searchEnabled\",\"@placeholder\",\"@allowClear\",\"@renderInPlace\"],[[24,0,[\"operatorList\"]],[24,0,[\"operator\"]],[24,0,[\"onOperatorChange\"]],false,\"Select Operator\",true,true]],{\"statements\":[[0,\"\\n    \"],[1,[24,1,[\"display\"]],false],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/dynamic-filter/select-operator.hbs"
    }
  });

  _exports.default = _default;
});