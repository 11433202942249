define("@smith-carson/ui/templates/components/sc-ranking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x8jysYoS",
    "block": "{\"symbols\":[\"star\",\"index\",\"star\",\"index\"],\"statements\":[[4,\"each\",[[29,\"-each-in\",[[24,0,[\"assignedStars\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"role\",\"button\"],[9],[0,\"\\n        \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\",\"@color\"],[\"star\",\"fa\",\"warning\"]]],[0,\"\\n    \"],[3,\"on\",[\"click\",[29,\"action\",[[24,0,[]],\"setNewValue\",[24,3,[]]],null]]],[10],[0,\"\\n\"]],\"parameters\":[3,4]},null],[4,\"each\",[[29,\"-each-in\",[[24,0,[\"allowedStars\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"role\",\"button\"],[9],[0,\"\\n        \"],[5,\"sc-icon\",[],[[\"@icon\",\"@iconSet\",\"@color\"],[\"star\",\"fa\",\"default\"]]],[0,\"\\n    \"],[3,\"on\",[\"click\",[29,\"action\",[[24,0,[]],\"setNewValue\",[24,1,[]]],null]]],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/templates/components/sc-ranking.hbs"
    }
  });

  _exports.default = _default;
});